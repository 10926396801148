import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import React from "react";
import emptybag from "../../assets/emptybag.png";

const CartEmpty = ({onCartToggle }) => {
  return (
    <>
      <div
        className="flex items-center justify-center 
      flex-col h-screen p-11 text-center gap-7">
        <img
          src={emptybag}
          alt="emptybag"
          className="w-40 lg:w-36 md:sm-28 h-auto object-fill translate-all duration-300
        hover:scale-110"
        />

        <button
          type="button"
          onClick={onCartToggle}
          className=" button-theme flex items-center justify-center 
          gap-3 py-2 bg-gradient-to-b from-amber-500 to-orange-500 shadow-lg
        shadow-orange-500 text-slate-900 text-sm px-5 font-semibold ">
          <ArrowLeftIcon className="w-5 h-5 text-slate-900 " />
          <span className="">Back To Nike Store!!!</span>
        </button>
      </div>
    </>
  );
};

export default CartEmpty;

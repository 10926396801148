import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  HeartIcon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
} from "@heroicons/react/24/outline";
import logo from "../assets/logo.png";
import { selectTotalQTY, setOpenCart } from "../App/CartSlice";

const NavBar = () => {
  const [moveNaveBar, setMoveNavBar] = useState(false);

  const dispatch = useDispatch();
  const totalQTY = useSelector(selectTotalQTY);

  const onCartToggle = () => {
    dispatch(setOpenCart({ cartState: true }));
  };

  const onNavScroll = () => {
    if (window.scrollY > 30) {
      setMoveNavBar(true);
    } else {
      setMoveNavBar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onNavScroll);

    return () => {
      window.removeEventListener("scroll", onNavScroll);
    };
  }, []);

  return (
    <>
      <header
        className={
          !moveNaveBar
            ? "absolute top-7 left-0 right-0 opacity-100 z-50 mt-4"
            : "fixed top-0 left-0 right-0 h-[9vh] flex items-center justify-center opacity-100 z-50 blur-effect-theme m-auto"
        }>
        <nav className="flex items-center justify-between nike-container">
          {/* Left Nav */}
          <div className="flex items-center">
            <img
              src={logo}
              alt="logo"
              className={`
            w-16 h-auto 
            ${moveNaveBar && "filter brightness-0"}
            `}
            />
          </div>

          {/* Right Nav */}

          <ul className="flex items-center justify-center gap-2 mr-[5rem] sm:mr-[1rem]">
            <li className="grid items-center">
              <MagnifyingGlassIcon
                className={`icon-style ${
                  moveNaveBar && "text-slate-900 transition-all duration-300"
                }`}
              />
            </li>
            <li className="grid items-center">
              <HeartIcon
                className={`icon-style ${
                  moveNaveBar && "text-slate-900 transition-all duration-300"
                }`}
              />
            </li>
            <li className="grid items-center">
              <button
                onClick={onCartToggle}
                type="button"
                className="border-none outline-none 
              active:scale-110 transition-all duration-300 relative">
                <ShoppingBagIcon
                  className={`icon-style ${
                    moveNaveBar && "text-slate-900 transition-all duration-300"
                  }`}
                />
                <div
                  className={`absolute top-4 right-0  
                   w-4 h-4 text-[0.65rem] font-medium rounded-full flex items-center justify-center
                   hover:scale-110 translate-all duration-300    
                ${
                  moveNaveBar
                    ? " bg-slate-900 text-slate-100 shadow-slate-900"
                    : "bg-slate-100 text-slate-900 shadow-slate-100"
                }`}>
                  {totalQTY}
                </div>
              </button>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default NavBar;

import React from "react";
import "./App.css";
import {
  Hero,
  Sales,
  FlexContent,
  Stories,
  Footer,
  NavBar,
  Cart,
} from "./Components";
import {
  heroApi,
  popularSales,
  topRateSales,
  highlight,
  sneaker,
  story,
  footerAPI,
} from "./Data/data.js";

const App = () => {
  return (
    <>
      <NavBar />
      <Cart />
      <main className="flex flex-col gap-16 relative ">
        <Hero heroApi={heroApi} />
        <Sales endpoint={popularSales} ifExists />
        <FlexContent endpoint={highlight} ifExists />
        <Sales endpoint={topRateSales} />
        <FlexContent endpoint={sneaker} />
        <Stories endpoint={story} />
        <Footer footerAPI={footerAPI} />
      </main>
    </>
  );
};

export default App;
